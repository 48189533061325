x<template>
  <v-container
    id="login-view"
    class="fill-height"
    tag="section"
  >
    <v-row justify="center">
      <v-col cols="12">
        <v-slide-y-transition appear>
          <material-card
            light
            rounded
            max-width="700"
            class="mx-auto"
            color="primary"
            full-header
          >
            <template #heading>
              <div class="text-center pa-5">
                <div class="text-h4 font-weight-bold white--text">
                  {{ appName }}
                </div>
              </div>
            </template>

            <v-card-text class="text-center">
              <div class="text-center font-weight-light">
                {{ $t('signup.title') }}
              </div>

              <div
                v-if="successfulRegistration"
                class="font-weight-bold mt-4"
              >
                {{ $t('signup.successful') }}
              </div>

              <validation-observer
                v-else
                v-slot="{ handleSubmit }"
              >
                <form @submit.prevent="handleSubmit(submit)">
                  <v-row>
                    <v-col cols="6">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        :name="$t('firstName').toLowerCase()"
                      >
                        <v-text-field
                          v-model="form.first_name"
                          :error-messages="errors"
                          color="primary"
                          :placeholder="$t('firstName')"
                          prepend-icon="mdi-account-box"
                          validate-on-blur
                        />
                      </validation-provider>
                    </v-col>
                    <v-col cols="6">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        :name="$t('lastName').toLowerCase()"
                      >
                        <v-text-field
                          v-model="form.last_name"
                          :error-messages="errors"
                          color="primary"
                          :placeholder="$t('lastName')"
                          prepend-icon="mdi-account-box"
                          validate-on-blur
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>

                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|email"
                    :name="$t('email').toLowerCase()"
                  >
                    <v-text-field
                      v-model="form.email"
                      :error-messages="errors"
                      color="primary"
                      :placeholder="$t('email')"
                      prepend-icon="mdi-email"
                      validate-on-blur
                    />
                  </validation-provider>

                  <v-row>
                    <v-col cols="6">
                      <validation-provider
                        v-slot="{ errors }"
                        vid="password"
                        rules="required|min:8"
                        :name="$t('password').toLowerCase()"
                      >
                        <v-text-field
                          v-model="form.password"
                          :error-messages="errors"
                          class="mb-8"
                          color="primary"
                          type="password"
                          :placeholder="$t('password')"
                          prepend-icon="mdi-lock-outline"
                          validate-on-blur
                        />
                      </validation-provider>
                    </v-col>
                    <v-col cols="6">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|confirmed:password"
                        :name="$t('confirmPassword').toLowerCase()"
                      >
                        <v-text-field
                          v-model="form.password_confirmation"
                          :error-messages="errors"
                          class="mb-8"
                          color="primary"
                          type="password"
                          :placeholder="$t('confirmPassword')"
                          prepend-icon="mdi-lock-outline"
                          validate-on-blur
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>

                  <div class="d-flex justify-space-around mb-6">
                    <router-link
                      to="/login"
                      class="font-weight-bold text-decoration-none"
                    >
                      {{ $t('signup.alreadyHave') }}
                    </router-link>
                  </div>

                  <SubmitButton
                    :label="$t('submit')"
                    type="submit"
                  />
                </form>
              </validation-observer>
            </v-card-text>
          </material-card>
        </v-slide-y-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import serverError from '../mixins/serverError';
  export default {
    name: 'RegisterView',

    mixins: [serverError],

    data: () => ({
      successfulRegistration: false,
      form: {
        email: null,
        password: null,
        password_confirmation: null,
        first_name: null,
        last_name: null,
      },
    }),

    computed: {
      ...mapGetters({
        accountCreationEnabled: 'app/accountCreationEnabled',
        processing: 'global/getProcessing',
        appName: 'app/appName',
      }),
    },

    created () {
      if (!this.accountCreationEnabled) {
        this.$router.push('/login');
      }
    },

    methods: {
      submit () {
        if (!this.processing) {
          const { email, password, password_confirmation, last_name, first_name } = this.form
          const payload = { email, password, password_confirmation, last_name, first_name };
          this.$store.dispatch('auth/register', payload)
            .then(response => {
              this.successfulRegistration = true;
            })
            .catch(error => {
              this.displayErrors(error);
            });
        }
      },
    },
  }
</script>
