var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-height",attrs:{"id":"login-view","tag":"section"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-slide-y-transition',{attrs:{"appear":""}},[_c('material-card',{staticClass:"mx-auto",attrs:{"light":"","rounded":"","max-width":"700","color":"primary","full-header":""},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('div',{staticClass:"text-center pa-5"},[_c('div',{staticClass:"text-h4 font-weight-bold white--text"},[_vm._v(" "+_vm._s(_vm.appName)+" ")])])]},proxy:true}])},[_c('v-card-text',{staticClass:"text-center"},[_c('div',{staticClass:"text-center font-weight-light"},[_vm._v(" "+_vm._s(_vm.$t('signup.title'))+" ")]),(_vm.successfulRegistration)?_c('div',{staticClass:"font-weight-bold mt-4"},[_vm._v(" "+_vm._s(_vm.$t('signup.successful'))+" ")]):_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('firstName').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"color":"primary","placeholder":_vm.$t('firstName'),"prepend-icon":"mdi-account-box","validate-on-blur":""},model:{value:(_vm.form.first_name),callback:function ($$v) {_vm.$set(_vm.form, "first_name", $$v)},expression:"form.first_name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('lastName').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"color":"primary","placeholder":_vm.$t('lastName'),"prepend-icon":"mdi-account-box","validate-on-blur":""},model:{value:(_vm.form.last_name),callback:function ($$v) {_vm.$set(_vm.form, "last_name", $$v)},expression:"form.last_name"}})]}}],null,true)})],1)],1),_c('validation-provider',{attrs:{"rules":"required|email","name":_vm.$t('email').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"color":"primary","placeholder":_vm.$t('email'),"prepend-icon":"mdi-email","validate-on-blur":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"password","rules":"required|min:8","name":_vm.$t('password').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-8",attrs:{"error-messages":errors,"color":"primary","type":"password","placeholder":_vm.$t('password'),"prepend-icon":"mdi-lock-outline","validate-on-blur":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"required|confirmed:password","name":_vm.$t('confirmPassword').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-8",attrs:{"error-messages":errors,"color":"primary","type":"password","placeholder":_vm.$t('confirmPassword'),"prepend-icon":"mdi-lock-outline","validate-on-blur":""},model:{value:(_vm.form.password_confirmation),callback:function ($$v) {_vm.$set(_vm.form, "password_confirmation", $$v)},expression:"form.password_confirmation"}})]}}],null,true)})],1)],1),_c('div',{staticClass:"d-flex justify-space-around mb-6"},[_c('router-link',{staticClass:"font-weight-bold text-decoration-none",attrs:{"to":"/login"}},[_vm._v(" "+_vm._s(_vm.$t('signup.alreadyHave'))+" ")])],1),_c('SubmitButton',{attrs:{"label":_vm.$t('submit'),"type":"submit"}})],1)]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }